body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: #FFF6EB;
}
.reveal{
  transform: translateY(0);
  opacity: 1;
}

.active{
  position: relative;
  transform: translateX(350px);
  opacity: 0;
  transition: 1.5s all ease;
}
.back{
  position: relative;
  transform: translateX(-0px);
  opacity: 1;
  transition: 1s all ease;
}
.dvvid{
  background-image: url('./assets/OBJECTS (4).svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.comment_box{
  background-image: url('./assets/Group 25.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
/* input[type="file"] {
  display: none;
} */